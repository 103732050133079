import { Routes, Route, useLocation } from 'react-router-dom';
import LayoutLanding from '@layout/LayoutLanding';
import LayoutDefault from '@layout/LayoutDefault';
import Landing from '@pages/Landing';
import MainWrap from '@pages/MainWrap';

export default function AppRoute(): JSX.Element {
  return (
    <Routes>
      <Route element={<LayoutDefault />}>
        {/*    <Route path='/main' element={<MainWrap />} /> */}
      </Route>

      <Route element={<LayoutLanding />}>
        <Route path='/' element={<Landing />} />
      </Route>
    </Routes>
  );
}
