import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { guestId } from '@stores/guest';
import { getVersion } from '@services/VersionService';
import './layoutDefault.scss';

export default function LayoutDefault() {

  const { t } = useTranslation();

  const [guestIdState, setGuestIdState] = useRecoilState(guestId);


  const [versionData, setVersionData] = useState({
    server_type: '',
    api_version: '',
    is_display: '',
  });

  const getVersionData = async () => {
    try {
      const data = await getVersion(guestIdState);
      setVersionData(data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVersionData();
  }, []);

  return (
    <>
      {versionData?.is_display === 'Y' ? (
        <div className='version-wrap'>
          <span>개발환경: {versionData.server_type}</span> &#47;
          <span> 버전: {versionData.api_version}</span>
        </div>
      ) : null}


      <div>
        <Outlet />
      </div>


    </>
  );
}
