import landingGlobal from '@assets/images/Landing/landing_global.png';

export default function LandingSectionGlobal() {
  return (
    <section className='small-slide landing-box landing-global-box'>
      <div className='landing-box-contents'>
        <h2 className='landing-title'>
          <span className='landing-title-small'>GLOBAL</span>
          너, 나, 그리고 우리
          <br />
          SHAKER를 통해 세계로
        </h2>
        <div className='landing-txt-box'>
          <p className='landing-txt-small'>
            SHAKER와 함께 세계 각국의 인플루언서들과
            <br /> 소통하고, 글로벌 커뮤니티에 참여하세요!
          </p>
          <p className='landing-txt-big'>
            당신의 이야기가 세계로 울려 퍼집니다.
          </p>
        </div>
      </div>

      <div className='landing-box-img'>
        <img src={landingGlobal} alt='Shaker' />
      </div>
    </section>
  );
}
