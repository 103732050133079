import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Mousewheel } from 'swiper';
import 'swiper/css';
import LandingSectionStart from './LandingSectionStart';
import LandingSectionInfluencer from './LandingSectionInfluencer';
import LandingSectionGlobal from './LandingSectionGlobal';
import LandingSectionComing from './LandingSectionComing';
import './landing.scss';

SwiperCore.use([Keyboard, Mousewheel]);

export default function Landing() {
  const innerHeight = document.documentElement.clientHeight;
  const [modalInnerSize, setModalInnerSize] = useState(innerHeight);

  useEffect(() => {
    function resizeModal() {
      const viewHeight = document.documentElement.clientHeight;
      if (viewHeight) {
        setModalInnerSize(viewHeight);
      }
    }
    resizeModal();
    window.addEventListener('resize', resizeModal);
  }, []);

  return (
    <main className='landing-wrap' style={{ height: `${modalInnerSize}px` }}>
      <Swiper
        className='swiper mySwiper'
        direction='vertical'
        slidesPerView={1}
        spaceBetween={0}
        mousewheel
      >
        <SwiperSlide>
          <LandingSectionStart />
        </SwiperSlide>
        <SwiperSlide>
          <LandingSectionInfluencer />
        </SwiperSlide>
        <SwiperSlide>
          <LandingSectionGlobal />
        </SwiperSlide>
        <SwiperSlide>
          <LandingSectionComing />
        </SwiperSlide>
      </Swiper>
    </main>
  );
}
