import landingFooter from '@assets/images/LandingFooter/landingfooter.png';

export default function LandingFooter() {
  return (
    <footer className='landing-footer-wrap'>
      <img
        className='landing-footer-img'
        src={landingFooter}
        alt='SecretTown and STARinTV'
      />
    </footer>
  );
}
