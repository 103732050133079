import LandingFooter from '@components/layouts/Footer/LandingFooter';
import landingComing from '@assets/images/Landing/landing_coming.png';
import gradationBg from '@assets/images/Landing/gradation_bg.png';
import landingComingBg from '@assets/images/Landing/landing_coming_bg.png';

export default function LandingSectionComing() {
  return (
    <section className='big-slide landing-box landing-coming-box'>
      <div className='landing-box-contents'>
        <h2 className='landing-title'>SHAKER IS COMING</h2>
        <div className='landing-txt-box'>
          <p className='landing-txt-small'>
            인플루언서와 팬이 만드는 새로운 쇼핑 패러다임
            <br /> SHAKER가 곧 오픈 예정입니다.
          </p>
        </div>
        <div className='landing-box-img landing-coming-box-img'>
          <img src={landingComing} alt='Shaker App image' />
        </div>

        <LandingFooter />

        <img
          className='gradation-img star-bg-img'
          src={landingComingBg}
          alt='background'
        />
      </div>
      <img className='gradation-img' src={gradationBg} alt='background image' />
    </section>
  );
}
