import LandingHeader from '@components/layouts/Header/LandingHeader';
import landingStart from '@assets/images/Landing/landing_start.png';
import gradationBg from '@assets/images/Landing/gradation_bg.png';
import landingStartTxt from '@assets/images/Landing/landing_start_txt.png';

export default function LandingSectionStart() {
  return (
    <>
      <section className='big-slide landing-box landing-start-box'>
        <LandingHeader />

        <div className='landing-box-contents'>
          <h2 className='landing-title'>
            <span className='landing-title-small'>
              팬과 인플루언서가 하나되는
            </span>
            글로벌 팬덤 플랫폼, 쉐이커
          </h2>

          <p className='landing-start-big-txt'>SHAKER</p>
          <p className='landing-start-btn'>
            <img src={landingStartTxt} alt='준비중입니다.' />
          </p>

          <div className='landing-box-img'>
            <img src={landingStart} alt='Shaker' />
          </div>
        </div>

        <img
          className='gradation-img'
          src={gradationBg}
          alt='background image'
        />
      </section>
    </>
  );
}
