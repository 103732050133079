import landingnInfluencer from '@assets/images/Landing/landing_influencer.png';

export default function LandingSectionInfluencer() {
  return (
    <>
      <section className='small-slide landing-box landing-influencer-box'>
        <div className='landing-box-contents'>
          <h2 className='landing-title'>
            <span className='landing-title-small'>INFLUENCER</span>
            당신의, 당신에 의한, 당신을 위한
            <br />
            인플루언서 상품
          </h2>

          <div className='landing-txt-box'>
            <p className='landing-txt-small'>
              SHAKER는 인플루언서와 독점적인 제휴를 맺어,
              <br />
              다른 플랫폼에서 찾을 수 없는
              <br />
              특별한 굿즈 및 상품을 제공합니다!
            </p>
            <p className='landing-txt-big'>
              진정한 '나'를 위한 쇼핑이 시작됩니다.
            </p>
          </div>
        </div>

        <div className='landing-box-img'>
          <img src={landingnInfluencer} alt='Influencer' />
        </div>
      </section>
    </>
  );
}
